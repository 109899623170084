import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import * as ExcelJS from 'exceljs';
import { InvoiceLine } from 'app/models/InvoiceLine';

@Component({
	selector: 'app-spreadsheet-file-input',
	templateUrl: './spreadsheet-file-input.component.html',
	styleUrls: ['./spreadsheet-file-input.component.scss']
})
export class SpreadsheetFileInputComponent {

	@Output() addInvoiceLineFromSheet = new EventEmitter<InvoiceLine>();
	@Output() updateManualInvoiceSettings = new EventEmitter<'basic'|'advanced'>();
	@ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;

	fileName: String = null;
	isValidSheet: boolean;

	errors: string[] = [];

	onFileSelected(event: Event): void {
		const element = event.target as HTMLInputElement;
		const file:File = element.files[0];

		if (file) {
			const fileReader = new FileReader();

			fileReader.onload = (e: ProgressEvent<FileReader>) => {
				const arrayBuffer: ExcelJS.Buffer = e.target.result as ArrayBuffer;
				this.parseExcel(arrayBuffer, file.name);
			};
	
			fileReader.readAsArrayBuffer(file);
		}
	}

	resetFileUpload(): void {
		this.isValidSheet = null;
		this.fileName = null;
		this.fileUpload.nativeElement.value = null;
		this.updateManualInvoiceSettings.emit('basic');
	}

	// this is where we should extract the columns
	private parseExcel(arrayBuffer: ExcelJS.Buffer, filename: string): void {
		const workbook = new ExcelJS.Workbook();
		workbook.xlsx.load(arrayBuffer).then((workbook) => {
			const isValidSheet = this.checkIsValidSheet(workbook.worksheets[0]);
			this.isValidSheet = isValidSheet.isValid;
			const columnMap = isValidSheet.columnMap;
			this.fileName = filename;

			if(this.isValidSheet) {
				// set to advanced
				this.updateManualInvoiceSettings.emit('advanced')
				
				// if valid csv, then read				
				const worksheet = workbook.worksheets[0];
				this.processWorksheet(worksheet, columnMap);
			}	
		});
	}
	
	private processWorksheet(worksheet: ExcelJS.Worksheet, columnMap: Map<string, number>): void {
		
		const nameCol = columnMap.get('TM Name');
		const rateOfPayCol = columnMap.get('Rate of Pay');
		const regularHoursCol = columnMap.get('Regular\nHours');
		const ratesMap = this.getRatesMap(worksheet);

		// iterate through all non-empty rows
		worksheet.eachRow((row: ExcelJS.Row, rowNumber: number) => {
			if (rowNumber > 1) {
				const { isValidRow, nonRegularHours } = this.checkIsValidRow(row)
				if(isValidRow) {
					const rowData: ExcelJS.CellValue[] | { [key: string]: ExcelJS.CellValue } = row.values;

					const name = rowData[nameCol];
					const rateOfPay = rowData[rateOfPayCol].result;
					const regularHours = rowData[regularHoursCol];

					const invoiceLine = {					
						amount: rateOfPay,
						primaryValue: name,
						quantity: regularHours,
						secondaryValue: "Regular Hours",
					};

					this.addInvoiceLineFromSheet.emit(invoiceLine);
					this.addNonRegularInvoiceLinesFromSheet(worksheet, columnMap, ratesMap, rowData, nonRegularHours, name);
				}
			}
		});
	}

	private getRatesMap(worksheet: ExcelJS.Worksheet): Map<string, number> {
		// look in sheet for pivot table
		// look for the "Regular Rate" cell
		const result = new Map<string, number>();
		
		const getRateByName = (worksheet: ExcelJS.Worksheet, name: string) => {
			let targetCell;
			worksheet.eachRow(row => row.eachCell(cell => {
				if (cell.value === name) {
					targetCell = cell;
				}
			}));

			const rowOffset = 3;
			const colOffset = 0;
			const rate = worksheet.getCell(targetCell.row + rowOffset, targetCell.col + colOffset).result as number;

			return rate;
		};

		const regularRate = getRateByName(worksheet, 'Regular Rate');
		const overtimeRate = getRateByName(worksheet, 'Overtime Rate');
		const nightPremiumRate = getRateByName(worksheet, 'Night Premium Rate');
		const weekendPremiumRate = getRateByName(worksheet, 'Weekend Premium Rate');
		const paidNotWorkedRate = getRateByName(worksheet, 'Paid Not Worked Rate');
		const sundayPremiumRate = getRateByName(worksheet, 'Sunday Premium Rate');
		const holidayWorkedRate = getRateByName(worksheet, 'Holiday Worked Rate');

		result.set('Regular Hours', regularRate);
		result.set('Overtime Hours', overtimeRate);
		result.set('Night Premium Hours', nightPremiumRate);
		result.set('Weekend Premium Hours', weekendPremiumRate);
		result.set('Paid Not Worked Hours', paidNotWorkedRate);
		result.set('Sunday Premium Hours', sundayPremiumRate);
		result.set('Holiday Worked Hours', holidayWorkedRate);

		return result;
	}

	private addNonRegularInvoiceLinesFromSheet(
		worksheet: ExcelJS.Worksheet,
		columnMap: Map<string, number>,
		ratesMap: Map<string, number>,
		rowData: ExcelJS.CellValue[] | { [key: string]: ExcelJS.CellValue },
		nonRegularHours: string[],
		name: string
	): void {

		// check for other additional columns to process
		for (const nonRegularHourType of nonRegularHours) {
			let hours;
			switch(nonRegularHourType) {
				case "Overtime Hours":
					hours = rowData[columnMap.get("Overtime\nHours")];
					break;
				case "Night Premium Hours":
					hours = rowData[columnMap.get("Night Premium\nHours")];
					break;
				case "Weekend Premium Hours":
					hours = rowData[columnMap.get("Weekend Premium\nHours")];
					break;
				case "Paid Not Worked Hours":
					hours = rowData[columnMap.get("Paid Not Worked\nHours")];
					break;
				case "Sunday Premium Hours":
					hours = rowData[columnMap.get("Sunday Premium\nHours")];
					break;
				case "Holiday Worked Hours":
					hours = rowData[columnMap.get("Holiday Worked Hours")];
					break;
				default:
			}
			const rate = ratesMap.get(nonRegularHourType);

			if(rate > 0 && hours > 0) {
				const invoiceLine = {
					amount: rate,
					primaryValue: name,
					quantity: hours,
					secondaryValue: nonRegularHourType,
				};

				this.addInvoiceLineFromSheet.emit(invoiceLine);
			}
		} 
	}
	
	private checkIsValidRow(row: ExcelJS.Row): {
		isValidRow: boolean;
		hasTmName?: boolean;
		hasRateOfPay?: boolean;
		nonRegularHours: string[];
	} {
		const rowData = row.values;

		function isNotEmpty(input) {
			return input !== '' && input !== undefined && input !== null;
		}

		const hasTmName = isNotEmpty(rowData[5]);
		const hasRateOfPay = isNotEmpty(rowData[7]);

		const isValidRow = hasTmName && hasRateOfPay
		let nonRegularHours = [];

		if(isValidRow) {
			if(isNotEmpty(rowData[9])) {
				nonRegularHours.push('Overtime Hours');
			}
			if(isNotEmpty(rowData[10])) {
				nonRegularHours.push('Night Premium Hours');
			}
			if(isNotEmpty(rowData[11])) {
				nonRegularHours.push('Weekend Premium Hours');
			}
			if(isNotEmpty(rowData[12])) {
				nonRegularHours.push('Paid Not Worked Hours');
			}
			if(isNotEmpty(rowData[13])) {
				nonRegularHours.push('Sunday Premium Hours');
			}
			if(isNotEmpty(rowData[14])) {
				nonRegularHours.push('Holiday Worked Hours');
			}
		}
		
		return { isValidRow, hasTmName, hasRateOfPay, nonRegularHours };
	}

	private checkIsValidSheet(worksheet: ExcelJS.Worksheet): {isValid: boolean, columnMap: Map<string, number>} {
		const headerRow = worksheet.getRow(1);

		const columnMap = new Map<string, number>();
		Object.keys(headerRow.values).forEach((key) => {
			columnMap.set(headerRow.values[key], Number(key));
		});

		const tmNameCol = columnMap.get('TM Name');
		const rateOfPayCol = columnMap.get('Rate of Pay');
		const regularHoursCol = columnMap.get('Regular\nHours');

		const hasTmNameCol = tmNameCol && headerRow.values[tmNameCol] === "TM Name";
		const hasRateOfPayCol = rateOfPayCol && headerRow.values[rateOfPayCol] === "Rate of Pay";
		const hasRegularHoursCol = regularHoursCol && headerRow.values[regularHoursCol] === "Regular\nHours";

		const isValid = hasTmNameCol && hasRateOfPayCol && hasRegularHoursCol;

		this.errors = [];
		if(!isValid) {
			if(!hasTmNameCol) {
				this.errors.push('\"TM Name\"');
			}
			if(!hasRateOfPayCol) {
				this.errors.push('\"Rate of Pay\"');
			}
			if(!hasRegularHoursCol) {
				this.errors.push('\"Regular Hours\"');
			}
		}

		return {isValid, columnMap};
	}
}