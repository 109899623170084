<div class="flex justify-center gap-x-10">
  <div class="w-full flex justify-around items-center text-center min-w-[32%] max-w-200 h-53 rounded bg-white shadow-card">
    <div class="mx-auto my-0">
      <p
        class="my-0 overflow-hidden font-bold tracking-wider whitespace-nowrap text-ellipsis max-w-130 text-primary text-16"
        [matTooltip]="invoice?.formattedAmount">
        {{invoice?.formattedAmount}}
      </p>
      <p class="my-0 overflow-hidden whitespace-nowrap text-ellipsis max-w-130 text-10 text-darkGray">Invoice Amount</p>
    </div>
  </div>
  <div class="w-full flex justify-around items-center text-center min-w-[32%] max-w-200 h-53 rounded bg-white shadow-card" *ngIf="invoice?.formattedInvoiceNumber">
    <div class="mx-auto my-0">
      <p
        class="my-0 overflow-hidden font-bold tracking-wider whitespace-nowrap text-ellipsis max-w-130 text-primary text-16"
        [matTooltip]="invoice?.formattedInvoiceNumber">
        {{invoice?.formattedInvoiceNumber}}
      </p>
      <p class="my-0 overflow-hidden whitespace-nowrap text-ellipsis max-w-130 text-10 text-darkGray">Invoice Number</p>
    </div>
  </div>
</div>
