<input type="file" class="hidden" accept=".xlsx" (change)="onFileSelected($event)" #fileUpload>

<div class="leading-30 mb-15">
  <span class="font-bold text-left cursor-pointer text-secondary text-12 leading-20 " *ngIf="!isValidSheet" (click)="fileUpload.click()">
    Upload Invoice Data
  </span>

  <mat-chip *ngIf="isValidSheet" (removed)="resetFileUpload()">
    {{fileName}}
    <button matChipRemove>
      <mat-icon>cancel</mat-icon>
    </button>
  </mat-chip>

  <p class="text-danger text-12" *ngIf="errors.length > 0">
    <span>Error: "{{fileName}}" is not a valid file. Could not find expected {{ errors.length > 1 ? 'columns' : 'column'}} {{errors}}</span>
  </p>
</div>