import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { Store } from '@ngrx/store';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Client } from 'app/models/Client';
import { CompanyUser } from 'app/models/CompanyUser';
import { Range } from 'app/models/range';
import { Project } from 'app/models/project';
import { ManualInvoiceLine } from 'app/models/ReportRequest';
import { State, getCompanyUsers, getProjectsList } from 'app/redux/reducers';
import { Observable } from 'rxjs';
import { GetCompanyUsersAction } from 'app/redux/actions/user';

@Component({
  selector: 'app-generate-manual-invoice-lines',
  templateUrl: './generate-manual-invoice-lines.component.html',
  styleUrls: ['./generate-manual-invoice-lines.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenerateManualInvoiceLinesComponent implements OnInit {
  @Input() invoiceLineControls: FormGroup[];
  @Input() clients: Client[] = [];
  @Input() manualInvoicesUseQuantity: boolean = false;
  @Output() addInvoiceLine = new EventEmitter<void>();
  @Output() deleteInvoiceLine = new EventEmitter<number>();
  @ViewChild(MatPaginator) public paginator: MatPaginator;

  public projects$: Observable<Project[]>;
  public companyUsers: CompanyUser[] = [];
  public pageSize: number = 5;
  public currentPageRange: Range<number> = {start: 0, end: this.pageSize};

  constructor(
    private store: Store<State>,
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef
  ) { }

  public ngOnInit(): void {
    this.projects$ = this.store.select(getProjectsList);
    this.store.select(getCompanyUsers)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(users => {
        this.companyUsers = [...this.companyUsers, ...users.data];
        if (users.totalPages > users.page) {
          this.store.dispatch(GetCompanyUsersAction({page: users.page  + 1}));
        }
      });
  }

  public changePage(): void {
    this.currentPageRange = {
      start: this.paginator.pageIndex * this.paginator.pageSize,
      end: (this.paginator.pageIndex + 1) * this.paginator.pageSize
    };
    this.cdr.detectChanges();
  }

  public onDeleteInvoiceLine(index: number): void {
    this.deleteInvoiceLine.emit(this.currentPageRange.start + index);
    if (this.invoiceLineControls.length === this.currentPageRange.start) {
      this.paginator.previousPage();
    }
    this.cdr.detectChanges();
  }

  public drop(event: CdkDragDrop<ManualInvoiceLine[]>): void {
    moveItemInArray(this.invoiceLineControls, event.previousIndex, event.currentIndex);
    this.invoiceLineControls.forEach((s, i) => s.get('ord').setValue(i));
  }

  public changeOrder(previewIndex: number, currentIndex: number): void {
    moveItemInArray(this.invoiceLineControls, previewIndex, currentIndex);
    this.invoiceLineControls.forEach((s, i) => s.get('ord').setValue(i));
  }
}
