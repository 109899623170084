<div class="flex items-center justify-between pb-10">
  <cartwheel-button class="w-110" size="sm" label="Add line" color="primary" type="raised-button" (onClick)="addInvoiceLine.emit()" />
  <div class="paginator">
    <mat-paginator
      hidePageSize
      [length]="invoiceLineControls.length"
      [pageSize]="pageSize"
      (page)="changePage()"
    />
  </div>
</div>
<div cdkDropList cdkDropListLockAxis="y" [class.min-h-340]="invoiceLineControls.length > 4" (cdkDropListDropped)="drop($event)">
  @for (invoiceLine of invoiceLineControls | slice : currentPageRange.start : currentPageRange.end; let i = $index; track 'invoice-line' + invoiceLine.get('ord').value) {
    <app-generate-manual-invoice-line
      cdkDrag
      class="box-border flex flex-col items-center justify-between py-5 bg-white cursor-move"
      [formGroup]="invoiceLine"
      [clients]="clients"
      [projects]="projects$ | async"
      [manualInvoicesUseQuantity]="manualInvoicesUseQuantity"
      [companyUsers]="companyUsers"
      [linesLength]="invoiceLineControls.length"
      [ord]="currentPageRange.start + i"
      (ordChange)="changeOrder(currentPageRange.start + i, $event)"
      (deleteInvoiceLine)="onDeleteInvoiceLine(i)"
    />
  }
</div>