import { Component, Input } from '@angular/core';
import { CompanyInvoice } from 'app/models/company-invoice';

@Component({
  selector: 'app-invoice-summary',
  templateUrl: './invoice-summary.component.html',
  styleUrls: ['./invoice-summary.component.scss']
})
export class InvoiceSummaryComponent {
  @Input() public invoice: CompanyInvoice;

  constructor() { }
}
