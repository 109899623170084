<div class="flex flex-col items-center py-5 pr-5 pl-15">
  <div class="flex justify-end w-full">
    <cartwheel-icon-button-radio-menu
      matIconName="filter_alt"
      [options]="historyTypes"
      [value]="selectedType"
      [disabled]="this.loading$ | async"
      (valueChange)="filterByType($event)"
    />
  </div>

  <mat-spinner
    class="mt-100"
    *ngIf="loading$ | async"
    [diameter]="30">
  </mat-spinner>

  @if (dataSource.data.length > 0 && !(loading$ | async)) {
    <cartwheel-timeline class="w-full" [history]="dataSource.data"/>
  }
  @if (dataSource.data.length === 0 && !(this.loading$ | async)) {
    <div class="mt-50">
      <span class="text-darkGray">No history found</span>
    </div>
  }

  <div class="paginator">
    <mat-paginator
      [class.hidden]="history.total <= 8 || (loading$ | async)"
      [length]="history.total"
      [pageSize]="8"
      [disabled]="loading$ | async"
      hidePageSize
    />
  </div>
</div>
