import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyInvoice } from 'app/models/company-invoice';

@Component({
  selector: 'app-invoice-information-dialog',
  templateUrl: './invoice-information-dialog.component.html',
  styleUrls: ['./invoice-information-dialog.component.scss']
})
export class InvoiceInformationDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CompanyInvoice,
    public dialogRef: MatDialogRef<InvoiceInformationDialogComponent>
  ) { }

}
