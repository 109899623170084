<div class="complex-table-container">
  <cartwheel-search-input (onFilter)="applyFilter($event)" />
  <div class="table-body">
    <table mat-table matSort multiTemplateDataRows [dataSource]="dataSource">
      <ng-container matColumnDef="primaryValue">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Primary value</th>
        <td mat-cell *matCellDef="let row">{{row.primaryValue}}</td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
        <td mat-cell *matCellDef="let row">{{row.formattedAmount}}</td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
        <td mat-cell *matCellDef="let row">{{row.formattedCreatedAt}}</td>
      </ng-container>

      <ng-container matColumnDef="expand" stickyEnd>
        <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
        <td mat-cell *matCellDef="let row">
          <mat-icon class="text-darkGray">{{expandedElement !== row ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}}</mat-icon>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let row" [attr.colspan]="columnsWithExpand.length">
          <div class="expansion-detail" [class.h-auto]="row == expandedElement">
            <div class="expansion-detail-body">
              <app-invoice-line class="block px-16 py-20 border border-solid rounded border-lightGray" [lineItem]="row" />
            </div>
          </div>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="columnsWithExpand"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnsWithExpand"
        class="cursor-pointer expansion-row"
        [class.expansion-expanded-row]="expandedElement === row"
        (click)="expandedElement = expandedElement === row ? null : row">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expansion-detail-row"></tr>
      <tr *matNoDataRow>
        <td [colSpan]="columns.length">
          <p>
            It looks like you don't have any invoice line items listed or they've been filtered out.
          </p>
          <img class="inline mt-16 w-150" src="assets/carty-sad.png" />
        </td>
      </tr>
    </table>
  </div>

  <div class="paginator">
    <mat-paginator
      [class.hidden]="dataSource.filteredData.length < 9"
      [pageSize]="8"
      hidePageSize
      showFirstLastButtons>
    </mat-paginator>
  </div>
</div>