<div [formGroup]="formGroup" class="flex items-end gap-x-10">
  <div class="flex flex-1 gap-x-10">
    <cartwheel-input
      label="Primary value"
      [options]="inputMenuOptions"
      [inputFormControl]="formGroup.get('primaryValue') | formControlType"
    />
    <cartwheel-input
      label="Secondary value"
      [options]="inputMenuOptions"
      [inputFormControl]="formGroup.get('secondaryValue') | formControlType"
    />
    <cartwheel-input
      type="number"
      [class.max-w-75]="manualInvoicesUseQuantity"
      [label]="manualInvoicesUseQuantity ? 'Rate' : 'Amount'"
      [inputFormControl]="formGroup.get('amount') | formControlType"
    />
    @if (manualInvoicesUseQuantity) {
      <cartwheel-input
        type="number"
        label="Quantity"
        [class.max-w-75]="manualInvoicesUseQuantity"
        [inputFormControl]="formGroup.get('quantity') | formControlType"
      />
    }
    <cartwheel-select
      class="w-full max-w-65"
      label="Ord"
      [options]="ordNumbers"
      [value]="formGroup.get('ord').value"
      (valueChange)="changeOrd($event)"
    />
  </div>
  <mat-icon class="leading-[33px] w-16 h-33 text-16 cursor-pointer text-danger" (click)="deleteInvoiceLine.emit()">
    delete
  </mat-icon>
</div>