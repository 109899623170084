<form class="px-5 pb-5 pt-30" [formGroup]="formGroup">
  <div class="flex flex-wrap justify-between gap-x-[10%] mb-30">
    @if (formGroup.getRawValue().status !== InvoiceStatus.Paid) {
      <cartwheel-button
        statusButton
        class="flex-1"
        color="secondary"
        label="Mark as Paid"
        type="raised-button"
        (onClick)="markAsPaid()"
        [status]="!((invoiceLoading$ | async) && actionType === 'markAsPaid') ? SuccessStatus.Enabled : SuccessStatus.IsLoading"
      />
    }
    @if (!(hasInvoiceDataSources$ | async)) {
      <cartwheel-button
        statusButton
        class="flex-1"
        color="warning"
        label="Download Invoice"
        type="flat-button"
        (onClick)="downloadInvoice()"
        [status]="!((invoiceLoading$ | async) && actionType === 'downloadInvoice') ? SuccessStatus.Enabled : SuccessStatus.IsLoading"
      />
    } @else {
      <cartwheel-button-dropdown
        statusButton
        color="warning"
        class="flex-1"
        label="Download Invoice"
        type="raised-button"
        [options]="invoiceActionOptions"
        [status]="!((invoiceLoading$ | async) && ['downloadInvoice', 'regenerateInvoice'].includes(actionType)) ? SuccessStatus.Enabled : SuccessStatus.IsLoading"
        (onClick)="downloadInvoice()"
        (valueChange)="toggleActions($event)"
      />
    }
  </div>
  <cartwheel-input class="block mb-30" label="Client Name" type="text" [inputFormControl]="formGroup.get('clientName') | formControlType" />
  @if (isValidDate(formGroup.controls.invoiceStartDate.getRawValue()) || isValidDate(formGroup.controls.invoiceEndDate.getRawValue())) {
    <div class="flex flex-wrap justify-between gap-x-[10%] mb-30">
      @if (isValidDate(formGroup.controls.invoiceStartDate.getRawValue())) {
        <cartwheel-datepicker class="flex-1" label="Invoice Statrt At" [dateFormControl]="formGroup.get('invoiceStartDate') | formControlType" />
      }
      @if (isValidDate(formGroup.controls.invoiceEndDate.getRawValue())) {
        <cartwheel-datepicker class="flex-1" label="Invoice End At" [dateFormControl]="formGroup.get('invoiceEndDate') | formControlType" />
      }
    </div>
  }
  <div class="flex flex-wrap justify-between gap-x-[10%] mb-30">
    <cartwheel-datepicker class="flex-1" label="Created At" [dateFormControl]="formGroup.get('createdAt') | formControlType" />
    <cartwheel-datepicker class="flex-1" label="Updated At" [dateFormControl]="formGroup.get('updatedAt') | formControlType" />
  </div>
  <div class="flex flex-wrap justify-between gap-x-[10%] mb-30">
    <cartwheel-select class="flex-1" label="Invoice Status" [selectFormControl]="formGroup.get('status') | formControlType" [options]="invoiceStatuses" />
    <cartwheel-datepicker class="flex-1" label="Due Date" [dateFormControl]="formGroup.get('dueAt') | formControlType" />
  </div>
  <div class="flex flex-wrap justify-between gap-x-[10%] mb-30">
    <cartwheel-input class="flex-1" label="Invoice Prefix" [inputFormControl]="formGroup.get('invoicePrefix') | formControlType"/>
    @if (showHours) {
      <cartwheel-input class="flex-1" label="Hours" [inputFormControl]="formGroup.get('hours') | formControlType"/>
    }
  </div>
  <cartwheel-chip-grid class="block mb-30" label="Destination" [chipFormControl]="formGroup.get('destination') | formControlType"/>
</form>