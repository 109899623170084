import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CartwheelNestedMenuOptions, CartwheelSelectOptions } from '@cartwheel/web-components';
import { Client } from 'app/models/Client';
import { CompanyUser } from 'app/models/CompanyUser';
import { Project } from 'app/models/project';
import { InvoiceLineMenuItem } from 'app/models/InvoiceLineMenu';


@Component({
  selector: 'app-generate-manual-invoice-line',
  templateUrl: './generate-manual-invoice-line.component.html',
  styleUrls: ['./generate-manual-invoice-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenerateManualInvoiceLineComponent implements OnChanges {
  @Input() formGroup: UntypedFormGroup;
  @Input() clients: Client[] = [];
  @Input() projects: Project[] = [];
  @Input() companyUsers: CompanyUser[] = [];
  @Input() manualInvoicesUseQuantity: boolean = false;
  @Input() linesLength: number = 0;
  @Input() ord: number = 0;
  @Output() ordChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() deleteInvoiceLine: EventEmitter<void> = new EventEmitter<void>();

  public inputMenuOptions: CartwheelNestedMenuOptions<string> = [];
  public ordNumbers: CartwheelSelectOptions<number> = [];

  constructor(
    private cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clients?.currentValue) {
      this.inputMenuOptions = [
        {
          label: 'Text',
          showOnlyLabel: true,
          value: '',
        },
        {
          label: 'Clients',
          children: this.formatClientsMenu()
        },
        {
          label: 'Projects',
          children: this.formatProjectMenu()
        },
        {
          label: 'Users',
          children: this.formatUserMenu()
        }
      ];
    }
    if (changes.linesLength) {
      this.ordNumbers = [...new Array(this.linesLength)].map((s, i) => ({label: `${i + 1}`, value: i}));
    }
    if (changes.ord?.currentValue !== undefined) {
      this.formGroup.patchValue({ord: changes.ord.currentValue});
    }
  }

  changeOrd(index: number): void {
    this.formGroup.get('ord').setValue(index);
    this.ordChange.emit(index);
    this.cdr.detectChanges();
  }

  private formatClientsMenu(): InvoiceLineMenuItem[] {
    return this.clients
      .map(client => ({
        label: client.clientName,
        children: Object.entries(client)
          .filter(s => ['clientName', 'invoiceTerms'].includes(s[0]) && s[1])
          .map(s => ({ label: s[0] === 'clientName' ? 'Client Name' : 'Invoice Terms', value: s[1] }))
      }));
  }

  private formatProjectMenu(): InvoiceLineMenuItem[] {
    return this.projects
      .map(project => ({
        label: project.projectName,
        children: [{ label: 'Project Name', value: project.projectName }]
      }));
  }

  private formatUserMenu(): InvoiceLineMenuItem[] {
    return this.companyUsers
      .map(companyUser => ({
        label: companyUser.userName,
        children: Object.entries({ ...companyUser, fullName: `${companyUser.firstName} ${companyUser.lastName}` })
          .filter(s => ['firstName', 'lastName', 'fullName', 'email'].includes(s[0]) && s[1])
          .map(s => {
            switch (s[0]) {
              case 'firstName':
                return { label: 'First Name', value: s[1] };
              case 'lastName':
                return { label: 'Last Name', value: s[1] };
              case 'fullName':
                return { label: 'Full Name', value: s[1] };
              default:
                return { label: 'Email', value: s[1] };
            }
          }) as InvoiceLineMenuItem[]
      }));
  }
}
