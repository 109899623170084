<div class="relative text-center text-white py-35 bg-secondary text-20">
  <span>
    Generate a manual invoice
  </span>
  <cartwheel-icon-button
    color="lighterGray"
    matIconName="clear"
    type="icon-button"
    class="absolute right-10 top-10"
    (onClick)="dialog.close()"/>
</div>
<form [formGroup]="submitManualInvoiceForm">
  <div class="py-20 px-50 cartwheel-base-dialog-scroll-content">
    <div class="w-full mb-15">
      <cartwheel-select
        label="Client Name (Required)"
        addNewType="dialog"
        [value]="selectedClient?.clientID"
        [options]="clientSelectOptions"
        (onClickAddNew)="addClient()"
        (valueChange)="selectClient($event)"
      />
      @if (selectedClient && (!selectedClient?.invoiceEmails || selectedClient?.invoiceEmails.length === 0)) {
        <p class="mt-5 text-danger text-12">
          This client isn't configured for invoicing. Please go to the Client page and update it.
        </p>
      }
    </div>

    <app-spreadsheet-file-input
      (addInvoiceLineFromSheet)="addInvoiceLineFromSheet($event)"
      (updateManualInvoiceSettings)="updateManualInvoiceSettings($event)"
    />

    <div class="flex flex-wrap gap-x-10 gap-y-15 mb-15">
      <cartwheel-input
        class="flex-1 min-w-165"
        label="Override Invoice Prefix"
        [inputFormControl]="submitManualInvoiceForm.get('overriddenInvoicePrefix') | formControlType"
      />
      <cartwheel-input
        class="flex-1 min-w-165"
        label="Preview Invoice Number"
        tooltip="This is just a sample of how the invoice number will look when generated. Your actual invoice number will be based on the incrementing invoice numbers associated with your account"
        [inputFormControl]="submitManualInvoiceForm.get('overriddenInvoicePrefixPreview') | formControlType"
      />
    </div>

    <div class="mb-15">
      <div class="flex items-center font-bold text-darkGray text-12 leading-20 gap-x-5">
        Manual Invoice Settings
        <mat-icon class="w-20 h-20 text-20" matTooltip="You need to create advanced invoices from desktop mode" *ngIf="isMobile$ | async">
          info_outline
        </mat-icon>
      </div>

      @if (!(isMobile$ | async)) {
        <mat-radio-group
          class="grid justify-start grid-cols-2"
          [value]="invoiceSettingLevel"
          (change)="changeInvoiceSetting($event)">
          <mat-radio-button color="primary" value="basic" [disabled]="loadingFromSpreadsheet">
            <span class="text-darkGray">
              Basic
            </span>
          </mat-radio-button>
          <mat-radio-button color="primary" value="advanced">
            <span class="text-darkGray">
              Advanced
            </span>
          </mat-radio-button>
        </mat-radio-group>
      }
      <span class="italic text-12 text-middleGray" *ngIf="loadingFromSpreadsheet">Uploading invoice data requires <b>Advanced</b>. For <b>Basic</b>, remove the file above.</span>
    </div>

    @if (invoiceSettingLevel === 'advanced' && !(isMobile$ | async)) {
      <app-generate-manual-invoice-lines
        formArrayName="invoiceLines"
        class="w-full mb-15"
        [invoiceLineControls]="invoiceLineControls"
        [clients]="clients"
        [manualInvoicesUseQuantity]="!!getGlobalSettingsModel.companySettings?.manualInvoicesUseQuantity"
        (addInvoiceLine)="addInvoiceLine()"
        (deleteInvoiceLine)="deleteInvoiceLine($event)"
      />
    } @else {
      <div class="w-full mb-15">
        <cartwheel-input type="number" label="Invoice Amount" [inputFormControl]="submitManualInvoiceForm.get('invoiceTotal') | formControlType" />
        @if (!amountValid && (submitManualInvoiceForm.get('invoiceTotal').dirty || submitManualInvoiceForm.get('invoiceTotal').touched)) {
          <p class="mt-5 text-danger text-12">
            Please ensure the amount is a number that is greater than 0
          </p>
        }
      </div>
    }

    <cartwheel-datepicker
      class="w-full mb-15"
      label="Invoice Due Date"
      [dateFilter]="invoiceDueDateFilter"
      [dateFormControl]="submitManualInvoiceForm.get('overridenDueDate') | formControlType"
    />

    <cartwheel-datepicker
      class="w-full mb-15"
      label="Invoice Period Ending Date"
      [dateFilter]="invoiceEndDateFilter"
      [dateFormControl]="submitManualInvoiceForm.get('invoiceEndDate') | formControlType"
    />

    <cartwheel-textarea
      class="block mb-15"
      label="Invoice Terms"
      [textareaFormControl]="submitManualInvoiceForm.get('invoiceTerms') | formControlType"
    />

    @if (amountValid) {
      <div class="invoice-total-grid">
        @if (!!selectedClient?.taxRate) {
          <label class="invoice-label text-14 text-middleGray">Invoice Subtotal</label>
          <span class="invoice-amount text-20 text-darkGray">
            {{ invoiceTotal | currency:getGlobalSettingsModel.currency }}
          </span>
          <label class="invoice-label text-14 text-middleGray">
            Tax Rate: {{selectedClient.taxRate.taxTypeName}} {{selectedClient.taxRate.taxPercentage}}%
          </label>
          <span class="invoice-amount text-20 text-darkGray">
            {{ (invoiceTotalTax - invoiceTotal) | currency:getGlobalSettingsModel.currency }}
          </span>
        }
        <label class="invoice-label text-14 text-middleGray">Invoice Total</label>
        <span class="invoice-amount text-20 text-darkGray">
          {{ invoiceTotalTax | currency:getGlobalSettingsModel.currency }}
        </span>
      </div>
    }
  </div>

  <div class="px-50 cartwheel-base-dialog-footer">
    <cartwheel-button
      statusButton
      class="w-full miniLandscape:w-140"
      type="raised-button"
      color="secondary"
      label="Generate"
      [status]="(!disabled && actionType !== 'preview') ? (invoicesLoading$ | async) : successStatus.Disabled"
      (onClick)="onSubmit()"
    />

    <cartwheel-button
      statusButton
      class="w-full miniLandscape:w-140"
      type="text-button"
      color="middleGray"
      label="Preview Invoice"
      [status]="(!disabled && actionType !== 'create') ? (invoicesLoading$ | async) : successStatus.Disabled"
      (onClick)="previewInvoice()"
    />
  </div>
</form>