<div class="card" [class.isMobile]="isMobile">
  <div class="content">
    <div class="header-container" id="header">
      <div class="heading">
        <div class="header-name">{{ invoice?.clientName }}</div>
        <cartwheel-icon-button
          matIconName="clear"
          color="darkGray"
          tooltip="Close invoice details"
          type="icon-button"
          (onClick)="closeDrawer.emit()"
        />
      </div>
    </div>
    <div>
      <app-invoice-summary [invoice]="invoice" />
      <mat-tab-group
        disableRipple
        class="card-tabs mt-15"
        [selectedIndex]="selectedTabIndex"
        (selectedTabChange)="selectTab($event)"
      >
        <mat-tab label="Details" [disabled]="invoiceLoading$ | async" />
        @if (invoice?.lineItems?.length) {
          <mat-tab label="Line Items" [disabled]="invoiceLoading$ | async" />
        }
        <mat-tab label="History" [disabled]="invoiceLoading$ | async" />
      </mat-tab-group>

      @switch (selectedTabLabel) {
        @case ('Details') {
          <app-invoice-details [formGroup]="editInvoiceForm" [filterOptions]="filterOptions"/>
        }
        @case ('Line Items') {
          <app-invoice-lineitems
            [globalSettingsModel]="getGlobalSettingsModel$ | async"
            [lineItems]="invoice.lineItems"
          />
        }
        @case ('History') {
          <app-invoice-history class="block" [invoice]="invoice" [history]="history$ | async" />
        }
      }
    </div>
  </div>
</div>
