import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ManualInvoiceLine } from 'app/models/ReportRequest';

@Component({
  selector: 'app-invoice-line',
  templateUrl: './invoice-line.component.html',
  styleUrls: ['./invoice-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceLineComponent implements OnInit {
  @Input() public lineItem: ManualInvoiceLine;
  public formGroup: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder
  ){}

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      ...this.lineItem
    });
    this.formGroup.disable();
  }

  public get shownQuantity(): boolean {
    return this.formGroup.controls.quantity.value > 1;
  }
}
