import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CartwheelIconButtonMenuOptions, CartwheelSelectOptions } from '@cartwheel/web-components';
import { DownloadInvoicesAction, MarkInvoicesAsPaidAction, RegenerateInvoiceFromSourceAction } from 'app/redux/actions/invoice';
import { State, getInvoiceDataSources, getInvoiceLoading } from 'app/redux/reducers';
import { InvoiceStatus, SuccessStatus } from 'app/shared/enums';
import { InvoiceDataSource } from 'app/models/invoice-data-source';
import { ConfirmDialogComponent } from 'app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { CompanyInvoicePagedRequest } from 'app/models/company-invoice';

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss']
})
export class InvoiceDetailsComponent implements OnInit {
  @Input() public formGroup: UntypedFormGroup;
  @Input() public filterOptions: CompanyInvoicePagedRequest = new CompanyInvoicePagedRequest();

  public invoiceLoading$: Observable<boolean>;
  public hasInvoiceDataSources$: Observable<boolean>;
  public separatorKeysCodes = [ENTER, COMMA] as const;
  public InvoiceStatus = InvoiceStatus;
  public SuccessStatus = SuccessStatus;
  public actionType: 'markAsPaid' | 'downloadInvoice' | 'regenerateInvoice' = null;
  public invoiceStatuses: CartwheelSelectOptions<InvoiceStatus> = [
    { label: 'Paid', value: InvoiceStatus.Paid },
    { label: 'Pending', value: InvoiceStatus.Pending },
    { label: 'Unpaid', value: InvoiceStatus.Unpaid }
  ];
  public invoiceActionOptions: CartwheelIconButtonMenuOptions<string> = [
    { label: 'Recreate invoice from Payroll data', value: 'recreate' }
  ];

  constructor(
    private store: Store<State>,
    private dialogService: MatDialog,
  ) { }

  public ngOnInit(): void {
    this.invoiceLoading$ = this.store.select(getInvoiceLoading)
      .pipe(
        map(s => s === SuccessStatus.IsLoading),
        tap(s => {
          if (!s) {
            this.actionType = null;
          }
        })
      );
    this.hasInvoiceDataSources$ = this.store.select(getInvoiceDataSources)
      .pipe(map(s => Object.values(s).some((t: InvoiceDataSource) => t.connectionStatus === SuccessStatus.Success)))
  }

  public get destinationEmails(): string[] {
    return this.formGroup.controls.destination.value?.split(',') ?? [];
  }

  public get showHours(): boolean {
    return this.formGroup.controls.hours.value ?? true;
  }

  public isValidDate(date: string): boolean {
    return new Date(date) > new Date(null);
  }

  public downloadInvoice(): void {
    this.actionType = 'downloadInvoice';
    this.store.dispatch(DownloadInvoicesAction({ invoices: [this.formGroup.getRawValue()] }));
  }

  public markAsPaid(): void {
    this.actionType = 'markAsPaid';
    this.dialogService.open(ConfirmDialogComponent, {
      data: {
        confirmationAction: () => {
          this.store.dispatch(MarkInvoicesAsPaidAction({ invoiceIds: [this.formGroup.getRawValue().invoiceId], filterOptions: this.filterOptions }));
        },
        message: 'Marking invoice as paid is an irreversible action. Are you sure you want to continue?'
      }
    });
  }

  public recreateInvoice(): void {
    this.actionType = 'regenerateInvoice';
    this.dialogService.open(ConfirmDialogComponent, {
      data: {
        confirmationAction: () => {
          this.dialogService.open(ConfirmDialogComponent, {
            data: {
              confirmationAction: () => {
                this.store.dispatch(RegenerateInvoiceFromSourceAction({ invoice: this.formGroup.getRawValue(), sendInvoiceEmail: true }))
              },
              cancellationAction: () => {
                this.store.dispatch(RegenerateInvoiceFromSourceAction({ invoice: this.formGroup.getRawValue(), sendInvoiceEmail: false }))
              },
              message: 'Do you want to resend this invoice to your customers?'
            }
          })
        },
        message: 'Are you sure you want to recreate this invoice from data from your payroll provider?'
      }
    })
  }

  public toggleActions(val: string): void {
    switch (val) {
      case 'recreate':
        this.recreateInvoice();
        break;
      default:
        break;
    }
  }
}
