import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { ManualInvoiceLine } from 'app/models/ReportRequest';
import { UserSettings } from 'app/models/user-settings';

@Component({
  selector: 'app-invoice-lineitems',
  templateUrl: './invoice-lineitems.component.html',
  styleUrls: ['./invoice-lineitems.component.scss'],
})
export class InvoiceLineitemsComponent implements OnChanges, AfterViewInit {
  @Input() public lineItems: ManualInvoiceLine[] = [];
  @Input() public globalSettingsModel: UserSettings;
  @ViewChild(MatPaginator) private paginator: MatPaginator;
  @ViewChild(MatSort) private sort: MatSort;

  public expandedElement: ManualInvoiceLine | null = null;
  public dataSource: MatTableDataSource<ManualInvoiceLine> = new MatTableDataSource([]);
  public columns: string[] = ['primaryValue', 'amount', 'createdAt'];
  public columnsWithExpand: string[] = [...this.columns, 'expand'];
  private typeOfCurrency = '';

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.lineItems?.currentValue) {
      this.dataSource.data = this.lineItems.map(s => ({
        ...s,
        formattedCreatedAt: dayjs(s.createdAt).format('M/D/YYYY'),
        formattedAmount: this.amountDueFormatting(s.amount)
      } as never as ManualInvoiceLine));
    }
  }

  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filter = '';
    this.dataSource.filterPredicate = (row: ManualInvoiceLine & { formattedCreatedAt: string }, filter: string) => {
      return this.columns.some(s => {
        return s === 'createdAt'
          ? row.formattedCreatedAt.includes(filter)
          : (`${row[s]}`?.toLowerCase()?.includes(filter) ?? false);
      });
    };
  }


  public applyFilter(val: string): void {
    this.dataSource.filter = val.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public toggleExpansion(row): void {
    this.expandedElement = this.expandedElement === row ? null : row;
    // $event.stopPropagation();
  }

  private amountDueFormatting(val: number): string {
    let locale = '';
    if (this.globalSettingsModel && this.globalSettingsModel.locale) {
      locale = this.globalSettingsModel.locale;
      this.typeOfCurrency = this.globalSettingsModel.currency;
    }
    let stringVal = '';
    if (locale !== '') {
      locale = 'en-US';
    }

    if (locale && this.typeOfCurrency) {
      stringVal = val.toLocaleString(locale, { style: 'currency', currency: this.typeOfCurrency });
    }
    return stringVal;
  }
}
